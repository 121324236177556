<template>
  <div class="main-content">
    <avue-crud
        :data="tableData"
        :option="option"
        :table-loading="showLoading"
        :page.sync="page"
        @on-load="onLoad"
    >
      <template #header>
        <div class="flex align-items marginBottom10 flex-wrap">
          <div class="marginRight10 marginBottom10">
            <el-button size="medium" type="primary" icon="el-icon-plus" @click="handleCreate">新增</el-button>
          </div>
          <div class="width200 marginRight10 marginBottom10">
            <el-select v-model="searchData.cityId" clearable placeholder="市" @change="handleCity">
              <el-option v-for="(item,index) in city" :key="index" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </div>
          <div class="width200 marginRight10 marginBottom10">
            <el-select v-model="searchData.countyId" clearable placeholder="区县" @change="handleCounty">
              <el-option v-for="(item,index) in county" :key="index" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </div>
          <div class="width200 marginRight10 marginBottom10">
            <el-select v-model="searchData.businessId" clearable placeholder="商圈">
              <el-option v-for="(item,index) in business" :key="index" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </div>
          <el-input size="medium" v-model="searchData.name" :maxlength="100" placeholder="输入楼盘名称/新房房源号" class="marginRight10 width300 marginBottom10"/>
<!--          <div class="width200 marginRight10 marginBottom10">-->
<!--            <el-select v-model="searchData.tradeState" clearable>-->
<!--              <el-option label="交易状态" value=""></el-option>-->
<!--              <el-option label="未发起" :value="1"></el-option>-->
<!--              <el-option label="正在交易" :value="2"></el-option>-->
<!--              <el-option label="交易完成" :value="3"></el-option>-->
<!--            </el-select>-->
<!--          </div>-->
          <div class="width200 marginRight10 marginBottom10">
            <el-select v-model="searchData.state" clearable>
              <el-option label="上架状态" value=""></el-option>
              <el-option label="上架" :value="1"></el-option>
              <el-option label="下架" :value="2"></el-option>
<!--              <el-option label="待发布" :value="3"></el-option>-->
            </el-select>
          </div>
          <div class="width200 marginRight10 marginBottom10">
            <el-select v-model="searchData.isVr" clearable>
              <el-option label="是否有VR链接" value=""></el-option>
              <el-option label="是" :value="1"></el-option>
              <el-option label="否" :value="2"></el-option>
            </el-select>
          </div>
          <div class="marginRight10 marginBottom10">
            <el-button size="medium" type="primary" icon="el-icon-search" @click="handleSearch">查询</el-button>
          </div>
          <div class="marginRight10 marginBottom10">
            <el-button size="medium" type="info" icon="el-icon-refresh-right" @click="handleReset">重置</el-button>
          </div>

          <div class="marginRight10 marginBottom10">
            <el-button size="medium"
                       icon="el-icon-upload2"
                       style="color: #ffffff;background: #f59a23;border-color: #f59a23"
                       @click="handleBatchHouse">批量导入房源</el-button>
          </div>

          <div class="marginRight10 marginBottom10">
            <el-button size="medium"
                       icon="el-icon-upload2"
                       style="color: #ffffff;background: #70b603;border-color: #70b603"
                       @click="handleBatchBroker">批量设置经纪人</el-button>
          </div>

          <div class="marginRight10 marginBottom10">
            <el-button size="medium"
                       icon="el-icon-upload2"
                       style="color: #ffffff;background: #ec808d;border-color: #ec808d"
                       @click="handleBatchVr">批量设置VR链接</el-button>
          </div>

          <div class="marginRight10 marginBottom10">
            <el-button size="medium"
                       icon="el-icon-download"
                       style="background: #c280ff;color: #ffffff;border-color: #c280ff"
                       @click="handleExport">导出</el-button>
          </div>
        </div>
      </template>
      <template slot="isSell" slot-scope="{ row }">
        <el-switch v-model="row.isSell" :disabled="row.state !== 1" @change="handleIsSell($event,row)" :active-value="1" :inactive-value="2"></el-switch>
      </template>

      <template slot="isRecommend" slot-scope="{ row }">
        <el-switch v-model="row.isRecommend" :disabled="row.state !== 1" @change="handleRecommend($event,row)" :active-value="1" :inactive-value="2"></el-switch>
      </template>

      <template slot="state" slot-scope="{ row }">
        <span v-if="row.state === 3">待发布</span>
        <el-switch v-else v-model="row.state" :disabled="row.brokerId === ''" @change="handleState($event,row)" :active-value="1" :inactive-value="2"></el-switch>
      </template>

      <template slot="isTop" slot-scope="{ row }">
        <el-switch v-model="row.isTop" :disabled="row.state !== 1" @change="handleTop($event,row)" :active-value="1" :inactive-value="2"></el-switch>
      </template>

      <template slot="menu" slot-scope="{ row }">
        <el-button type="text" size="mini" icon="el-icon-edit" @click="handleEdit(row)">编辑</el-button>
        <el-button type="text" size="mini" icon="el-icon-view" @click="handleView(row)">查看</el-button>
        <el-button type="text" v-if="row.tradeState !== 2 && row.tradeState !== 3" size="mini" icon="el-icon-delete" @click="handleDelete(row)">删除</el-button>
        <!--        设置VR链接-->
        <el-button type="text" size="mini" icon="el-icon-edit" @click="handleVr(row)">{{ row.isVr === 1 ? '编辑VR链接' : '设置VR链接' }}</el-button>
        <!--        设置经纪人-->
        <el-button type="text" size="mini" icon="el-icon-edit" @click="handleBroker(row)">设置经纪人</el-button>
      </template>
    </avue-crud>

    <el-dialog
        :title="`${isVrForm.isVr === 1 ? '编辑' : '设置'}VR链接`"
        :visible.sync="isVrDialogVisible"
        width="30%"
        :before-close="isVrHandleClose">
      <avue-form ref="form"
                 v-model="isVrForm"
                 :option="isVrFormOption"
                 @submit="isVrSubmit">
        <template slot="menuForm">
          <el-button icon="el-icon-close" @click="isVrHandleClose">取消</el-button>
        </template>
      </avue-form>
    </el-dialog>

    <Broker v-if="brokerDialogVisible"
            :brokerDialogVisible="brokerDialogVisible"
            :brokerForm="brokerForm"
            @close="close"
            ref="broker"
            @brokerChoice="brokerChoice"/>

    <Import v-if="importShow"
            :show="importShow"
            :action="importAction"
            :downloadLink="importDownloadLink"
            :problemLink="importProblem"
            @close="importClose"/>
  </div>
</template>

<script>
import {listBusiness, listCity, listCounty} from "@/api/house/second";
import { list,updateIsSell,updateRecommend,updateState,updateTop,deleteById,updateVr,updateAgent } from "@/api/house/new"
import { option } from './data'
import Broker from "../components/broker";
import Import from "../components/import";
import qs from "qs";
export default {
  name: "index",
  components: {
    Broker,
    Import
  },
  data(){
    return{
      city: [],
      county: [],
      business: [],
      searchData: {
        cityId: '',
        countyId: '',
        businessId: '',
        name: '',
        tradeState: '',
        state: '',
        isVr: ''
      },
      page:{
        total: 0,
        currentPage: 1
      },
      tableData: [],
      showLoading: false,
      option: option,

      // 是否有VR链接
      isVrDialogVisible: false,
      isVrForm: {
        id: '',
        isVr: 1,
        varLink: '',
      },
      isVrFormOption: {
        submitText: '确定',
        emptyBtn: false,
        column: [
          {
            label: '链接',
            prop: 'varLink',
            maxlength: 100,
            showWordLimit: true,
            span: 24,
            rules: [{
              required: true,
              message: "请输入链接",
              trigger: "blur"
            }]
          }
        ]
      },

      // 经纪人
      brokerDialogVisible: false,
      brokerForm: {
        id: '',
        brokerId: ''
      },

      importShow: false,
      importAction: '',
      importDownloadLink: '',
      importProblem: ''
    }
  },
  async mounted() {
    await this.getListCity();
  },
  methods: {
    async getListCity(){
      const { data } = await listCity();
      // console.log(data);
      this.city = data || [];
    },
    async getListCounty(id){
      const { data } = await listCounty({
        id: id
      });
      this.county = data || [];
    },
    async getListBusiness(id){
      const { data } = await listBusiness({
        id: id
      });
      this.business = data || [];
    },
    onLoad(){
      this.showLoading = true;
      list({
        page: this.page.currentPage,
        size: this.page.pageSize,
        ...this.searchData
      }).then(res => {
        this.showLoading = false;
        if(res.code === 200){
          this.tableData = res.data.records;
          this.page.total = res.data.total;
        }
      })
    },
    // 新增
    handleCreate(){
      // console.log('新增')
      this.$router.push({
        path: '/house/new/create',
        query: {
          type: 'create'
        }
      })
    },
    // 选择市
    async handleCity(e){
      this.searchData.countyId = '';
      this.searchData.businessId = '';
      this.county = [];
      this.business = [];
      e && await this.getListCounty(e);
    },
    // 选择区县
    async handleCounty(e){
      this.searchData.businessId = '';
      this.business = [];
      e && await this.getListBusiness(e);
    },
    // 查询
    handleSearch(){
      // console.log('查询')
      this.page.currentPage = 1;
      this.onLoad();
    },
    // 重置
    handleReset(){
      // console.log('重置')
      this.searchData = {
        cityId: '',
        countyId: '',
        businessId: '',
        name: '',
        tradeState: '',
        state: '',
        isVr: ''
      }
      this.county = [];
      this.business = [];
      this.page.currentPage = 1;
      this.onLoad();
    },
    handleIsSell(e,row){
      updateIsSell({
        id: row.id,
        isSell: e
      }).then(res => {
        if(res.code === 200){
          this.$message.success('操作成功');
        }else{
          this.$message.error(res.msg);
        }
        this.onLoad()
      })
    },
    // 首页推荐
    handleRecommend(e,row){
      updateRecommend({
        id: row.id,
        isRecommend: e
      }).then(res => {
        if(res.code === 200){
          this.$message.success('操作成功');
        }else{
          this.$message.error(res.msg);
        }
        this.onLoad()
      })
    },
    // 上架/下架
    handleState(e,row){
      // console.log('上架/下架',e,row)
      updateState({
        id: row.id,
        state: e
      }).then(res => {
        if(res.code === 200){
          this.$message.success('操作成功');
        }else{
          this.$message.error(res.msg);
        }
        this.onLoad()
      })
    },
    // 是否置顶
    handleTop(e,row){
      // console.log('上架/下架',e,row)
      updateTop({
        id: row.id,
        isTop: e
      }).then(res => {
        if(res.code === 200){
          this.$message.success('操作成功');
        }else{
          this.$message.error(res.msg);
        }
        this.onLoad()
      })
    },
    // 编辑
    handleEdit(row){
      console.log('编辑',row)
      this.$router.push({
        path: '/house/new/create',
        query: {
          id: row.id,
          type: 'create'
        }
      })
    },
    // 查看
    handleView(row){
      // console.log('查看',row)
      this.$router.push({
        path: '/house/new/create',
        query: {
          id: row.id,
          type: 'view'
        }
      })
    },
    // 删除
    handleDelete(row){
      // console.log('删除',row)
      this.$confirm('是否删除此新房房源信息?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        deleteById({
          id: row.id
        }).then(res => {
          if(res.code === 200){
            this.$message.success('删除成功');
            this.onLoad();
          }else{
            this.$message.error(res.msg);
          }
        })
      })
    },
    // 设置VR链接
    handleVr(row){
      this.isVrForm.id = row.id;
      this.isVrForm.isVr = row.isVr;
      this.isVrForm.varLink = row.varLink;
      this.isVrDialogVisible = true;
    },
    // 关闭设置VR链接
    isVrHandleClose(){
      this.isVrDialogVisible = false;
      setTimeout(()=>{
        this.isVrHandleReset();
      },500)
    },
    // 清空isVrForm表单
    isVrHandleReset(){
      this.isVrForm = {
        id: '',
        isVr: 1,
        varLink: ''
      }
    },
    // 提交设置VR链接
    isVrSubmit(form,done){
      // console.log('设置VR链接',form)
      updateVr({
        id: form.id,
        varLink: form.varLink
      }).then(res => {
        if(res.code === 200){
          this.$message.success('操作成功');
          this.isVrDialogVisible = false;
          setTimeout(()=>{
            this.isVrHandleReset();
          },500)
          this.onLoad();
        }else{
          this.$message.error(res.msg);
          done();
        }
      }).catch(()=> done())
    },

    // 设置经济人
    // 设置经纪人
    handleBroker(row){
      // console.log('设置经纪人',row)
      this.brokerForm.id = row.id;
      this.brokerForm.brokerId = row.brokerId;
      this.brokerDialogVisible = true;
    },
    // 关闭设置经纪人
    async close(){
      this.brokerDialogVisible = false;
      this.brokerForm = {
        id: '',
        brokerId: ''
      }
    },
    async brokerChoice(e){
      if(e.brokerId){
        await this.setUpdateAgent(e);
      }
    },
    async setUpdateAgent(e){
      const data = await updateAgent({
        id: e.id,
        brokerId: e.brokerId
      });
      if(data.code === 200){
        this.$message.success('设置成功');
        this.$refs.broker.setBrokerId(true)
        this.onLoad()
      }else{
        this.$message.error(data.msg)
        this.$refs.broker.setBrokerId(false)
      }
    },

    // 批量导入房源
    handleBatchHouse(){
      // console.log('批量导入房源')
      this.importAction = '/api/web/house/new/template/import';
      this.importDownloadLink = '/api/web/house/new/template/export';
      this.importProblem = '/api/web/house/new/template/error/export';
      this.importShow = true;
    },
    // 批量设置经纪人
    handleBatchBroker(){
      // console.log('批量设置经纪人')
      this.importAction = '/api/web/house/new/agent/template/import';
      this.importDownloadLink = '/api/web/house/new/agent/template/export';
      this.importProblem = '/api/web/house/new/agent/template/error/export';
      this.importShow = true;
    },
    // 批量设置VR链接
    handleBatchVr(){
      // console.log('批量设置VR链接')
      this.importAction = '/api/web/house/new/vr/template/import';
      this.importDownloadLink = '/api/web/house/new/vr/template/export';
      this.importProblem = '/api/web/house/new/vr/template/error/export';
      this.importShow = true;
    },
    // 导出
    handleExport(){
      // console.log('导出')
      window.open(`/api/web/house/new/export?${qs.stringify(this.searchData)}`,'_self');
    },
    // 导入弹框关闭
    importClose(e){
      this.importShow = false
      if(e === 1 || e === 2){
        this.onLoad()
      }
    }
  }
}
</script>

<style scoped>

</style>