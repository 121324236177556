<template>
  <el-dialog
      title="选择经纪人"
      :visible.sync="brokerDialogVisible"
      width="60%"
      :before-close="brokerHandleClose">
    <avue-crud
        :data="brokerTableData"
        :option="brokerOption"
        :table-loading="brokerShowLoading"
        :page.sync="brokerPage"
        @on-load="brokerOnLoad">
      >
      <template #header>
        <div class="flex align-items marginBottom20">
          <el-input size="medium" v-model="brokerSearchData.name" placeholder="输入姓名/手机号" class="width200"/>
          <el-button size="medium" type="primary" icon="el-icon-search" @click="brokerHandleSearch" class="marginLeft10">查询</el-button>
          <el-button size="medium" type="info" icon="el-icon-refresh-right" @click="brokerHandleReset" class="marginLeft10">重置</el-button>
        </div>
      </template>
      <template slot="menu" slot-scope="{ row }">
        <el-button v-if="choiceBrokerId !== row.id" type="text" size="mini" icon="el-icon-check" @click="handleChoice(row)">选择</el-button>
        <el-button v-else type="text" size="mini" icon="el-icon-close" @click="handleChoiceCancel()">取消选择</el-button>
      </template>
    </avue-crud>
  </el-dialog>
</template>

<script>
import {
  listAgent,
} from "@/api/house/second";
export default {
  name: "broker",
  props: {
    brokerDialogVisible: {
      type: Boolean,
      default: false
    },
    brokerForm: {
      type: Object,
      default: () => ({})
    }
  },
  data(){
    return{
      brokerSearchData: {
        name: ''
      },
      brokerPage:{
        total: 0,
        currentPage: 1,
        pageSize: 10
      },
      brokerTableData: [],
      brokerShowLoading: false,
      brokerOption: {
        title:'',
        titleSize:'h3',
        card: true,
        border: true,
        header: false,
        stripe: false, // 显示斑马纹
        showHeader: true, // 显示表头
        index: false, // 显示索引
        size: 'small',
        selection: false, // 显示多选框
        page: true, // 显示分页
        align:'center',
        menuAlign:'center',
        menuType: 'text',
        editBtn: false,
        delBtn: false,
        column: [
          {
            label: '用户昵称',
            prop: 'nickname'
          },
          {
            label: '用户手机号',
            prop: 'phone'
          },
          {
            label: '用户id',
            prop: 'code'
          },
          {
            label: '用户姓名',
            prop: 'username'
          }
        ]
      },
      choiceBrokerId: ''
    }
  },
  mounted() {
    if(this.brokerForm.brokerId){
      this.choiceBrokerId = this.brokerForm.brokerId;
    }
  },
  methods: {
    setBrokerId(e){
      if(e){
        this.brokerForm.brokerId = this.choiceBrokerId
      }else{
        this.choiceBrokerId = ''
      }
    },
    async brokerOnLoad(){
      const { data } = await listAgent({
        page: this.brokerPage.currentPage,
        size: this.brokerPage.pageSize,
        ...this.brokerSearchData
      });
      this.brokerTableData = data.records;
      this.brokerPage.total = data.total;
    },
    brokerHandleClose(){
      this.$emit('close')
    },
    // 搜索设置经纪人
    async brokerHandleSearch(){
      this.brokerPage.currentPage = 1;
      await this.brokerOnLoad();
    },
    // 重置设置经纪人
    async brokerHandleReset(){
      this.brokerSearchData = {
        name: ''
      }
      this.brokerPage.currentPage = 1;
      await this.brokerOnLoad();
    },
    // 选择设置经纪人
    handleChoice(row){
      this.choiceBrokerId = row.id;
      const form = {
        id: this.brokerForm.id,
        brokerId: this.choiceBrokerId === this.brokerForm.brokerId ? '' : this.choiceBrokerId
      }
      this.$emit('brokerChoice',form)
    },
    // 取消选择设置经纪人
    handleChoiceCancel(){
      this.choiceBrokerId = ''
    },
  }
}
</script>

<style scoped>

</style>