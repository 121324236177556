export const option = {
    title: '新房房源列表',
    titleSize: 'h3',
    card: true,
    border: true,
    header: false,
    stripe: false, // 显示斑马纹
    showHeader: true, // 显示表头
    index: false, // 显示索引
    size: 'small',
    selection: false, // 显示多选框
    page: true, // 显示分页
    align: 'center',
    menuAlign: 'center',
    menuType: 'text',
    menuWidth: 300,
    editBtn: false,
    delBtn: false,
    column: [
        {
            label: '新房房源号',
            prop: 'code',
            width: 200
        },
        {
            label: '房源标题',
            prop: 'title',
            width: 200
        },
        {
            label: '城市',
            prop: 'cityName',
            width: 150
        },
        {
            label: '区县',
            prop: 'countyName',
            width: 150
        },
        {
            label: '商圈',
            prop: 'businessName',
            width: 150
        },
        {
            label: '楼盘名称',
            prop: 'buildName',
            width: 150
        },
        {
            label: '参考均价',
            prop: 'averagePrice',
            width: 150
        },
        {
            label: '开盘时间',
            prop: 'openTime',
            width: 200
        },
        {
            label: '产权',
            prop: 'propertyRight',
            width: 150
        },
        {
            label: '标签',
            prop: 'labelName',
            width: 200
        },
        {
            label: '在售状态',
            prop: 'isSell',
            formatter: function(row, column, cellValue){
                return cellValue === 1 ? '在售' : cellValue === 2 ? '已售' : '/';
            },
            width: 150
        },
        // {
        //     label: '交易状态',
        //     prop: 'tradeState',
        //     formatter: function(row, column, cellValue){
        //         return cellValue === 1 ? '未发起' : cellValue === 2 ? '正在交易' : cellValue === 3 ? '交易完成' : '/';
        //     },
        //     width: 150
        // },
        // {
        //     label: '成交时间',
        //     prop: 'tradeTime',
        //     formatter: function(row, column, cellValue){
        //         return cellValue ? cellValue : '/';
        //     },
        //     width: 150
        // },
        // {
        //     label: '成交金额(万元)',
        //     prop: 'tradePrice',
        //     formatter: function(row, column, cellValue){
        //         return cellValue ? cellValue : '/';
        //     },
        //     width: 150
        // },
        {
            label: '首页推荐',
            prop: 'isRecommend',
            width: 150
        },
        {
            label: '上架状态',
            prop: 'state',
            width: 150
        },
        {
            label: '是否置顶',
            prop: 'isTop',
            width: 150
        },
        {
            label: '是否有VR链接',
            prop: 'isVr',
            formatter: function(row, column, cellValue){
                return cellValue === 1 ? '是' : cellValue === 2 ? '否' : '/';
            },
            width: 150
        },
        {
            label: '经纪人',
            prop: 'brokerName',
            formatter: function(row, column, cellValue){
                return cellValue ? cellValue : '/';
            },
            width: 150
        },
        {
            label: '经纪人手机号',
            prop: 'brokerPhone',
            formatter: function(row, column, cellValue){
                return cellValue ? cellValue : '/';
            },
            width: 150
        }
    ]
}
